<template>
  <div :class="type == 'view' ? 'mg-b-5' : ''">
    <el-card class="custom-card condition blue active" shadow="never">
      <div slot="header" class="clearfix">
        <span>Basic Information of Client (ข้อมูลบริษัท)</span>
      </div>
      <div>
        <el-card shadow="never" class="card-inside normal" :class="type">
          <el-row :gutter="15" type="flex" class="flex-wrap">
            <el-col :span="24" :md="12">
              <el-form-item
                label="Company name (ชื่อบริษัท)"
                prop="companyName"
              >
                <span v-if="type == 'view'">
                  {{ form.companyName }}
                </span>
                <el-select
                  v-else
                  id="select-companyName"
                  v-model="form.companyName"
                  filterable
                  clearable
                  @keydown.native="autoChage"
                  @clear="autoChage"
                  @change="setAddress"
                  placeholder="Company name"
                  :loading="loadingAuto"
                  :disabled="type == 'edit' ? true : false"
                >
                  <div v-infinite-scroll="scrollToSearch" class="infinite-list">
                    <el-option
                      class="infinite-list-item"
                      v-for="item in refRequisition"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item prop="address" label="Address Of Client (ที่อยู่)">
                <span
                  v-if="type == 'view'"
                  v-html="returnAddress(form.address)"
                >
                </span>
                <el-input v-else v-model="form.address" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="Contact person name (ชื่อผู้ติดต่อ) "
                prop="contactPerson"
              >
                <span v-if="type == 'view'">
                  {{ form.contactPerson }}
                </span>
                <el-input
                  v-else
                  v-model="form.contactPerson"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Phone number (เบอร์โทร)" prop="phone">
                <span v-if="type == 'view'">
                  {{ form.phone }}
                </span>
                <el-input v-else v-model="form.phone" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-row :gutter="20" type="flex" class="flex-wrap">
                <el-col :span="24" :md="12">
                  <el-form-item label="Type (ประเภท)" prop="type">
                    <div v-if="type == 'view'">
                      <span v-if="form.type == 1"> ตรวจ Food safety </span>
                      <span v-else-if="form.type == 2"> ตรวจ ISO </span>
                    </div>
                    <el-select
                      v-else
                      clearable
                      v-model="form.type"
                      placeholder=""
                      @change="selectOptionType"
                      @clear="selectOptionType"
                      :disabled="optionTwoType == false ? true : false"
                    >
                      <el-option
                        v-for="item in typeMaster"
                        :key="item.typeNum"
                        :label="item.typeName"
                        :value="item.typeNum"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="24" :md="12">
                  <el-form-item label="Company (บริษัท)" prop="company">
                    <div v-if="type == 'view'">
                      <span v-if="form.type == 2">
                        {{
                          mapDataOption(
                            form.company,
                            optionsCompanyIso,
                            "company"
                          )
                        }}
                      </span>
                      <span v-if="form.type == 1"> - </span>
                    </div>
                    <el-select
                      v-else
                      clearable
                      v-model="form.company"
                      placeholder=""
                      :disabled="form.type == 2 && type == 'add' ? false : true"
                      @change="selectOptionCompany"
                    >
                      <template slot="empty">
                        <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                      </template>
                      <el-option
                        v-for="item in optionsCompany"
                        :key="item.companyNum"
                        :label="item.companyName"
                        :value="item.companyNum"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Standard (มาตรฐาน)" prop="standard">
                <span v-if="type == 'view'">
                  <span v-if="form.type == 1">
                    {{
                      mapDataOption(
                        form.standard,
                        optionsStandardFood,
                        "standard"
                      )
                    }}</span
                  >
                  <span v-else-if="form.type == 2">
                    {{
                      mapDataOption(
                        form.standard,
                        form.company == 1
                          ? optionsStandardIsoOne
                          : optionsStandardIsoTwo,
                        "standard"
                      )
                    }}
                  </span>
                </span>
                <el-select
                  v-else
                  clearable
                  v-model="form.standard"
                  placeholder=""
                  :disabled="
                    optionsStandard.length == 0 || type != 'add' ? true : false
                  "
                  @change="selectStandard"
                >
                  <template slot="empty">
                    <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                  </template>
                  <el-option
                    v-for="item in optionsStandard"
                    :key="item.standardNum"
                    :label="item.standardName"
                    :value="item.standardNum"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-row :gutter="20" type="flex" class="flex-wrap">
                <el-col :span="24" :md="12">
                  <el-form-item label="CAT No." prop="catNo">
                    <div v-if="type == 'view'">
                      {{ form.catNo }}
                    </div>
                    <el-select
                      v-if="type != 'view' && conditionTwoStandard"
                      v-model="form.catNo"
                      placeholder=""
                      :disabled="conditionTwoStandard == false ? true : false"
                      @change="seleteDefaultAuditCycle"
                    >
                      <el-option
                        v-for="item in optionsSetCatNo"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      v-else-if="type != 'view' && !conditionTwoStandard"
                      v-model="form.catNo"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" :md="12">
                  <el-form-item label="Audit cycle (รอบการตรวจติดตาม)">
                    <div v-if="type == 'view'">
                      <span v-if="form.auditCycle == 6"> 6 เดือน </span>
                      <span v-else-if="form.auditCycle == 12"> 12 เดือน </span>
                    </div>
                    <el-select
                      v-else
                      v-model="form.auditCycle"
                      placeholder=""
                      disabled
                    >
                      <el-option
                        v-for="item in optionsAuditCycle"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" class="mg-y-5">
              <el-row :gutter="15" type="flex" class="flex-wrap">
                <el-col :span="24" :md="12">
                  <el-form-item
                    prop="auditorId"
                    label="Choose auditor (เลือกผู้ตรวจ)"
                  >
                    <span v-if="type == 'view'">
                      <el-tag
                        class="detail-auditor view"
                        :key="indexS"
                        v-for="(item, indexS) in form.detailAuditor"
                        :disable-transitions="false"
                        closable
                      >
                        <p>
                          {{ item.name }} <br />
                          {{ item.email }} <br />
                          {{ item.phone }}
                        </p>
                      </el-tag>
                    </span>
                    <el-select
                      v-else
                      filterable
                      clearable
                      multiple
                      v-model="form.auditorId"
                      placeholder="Select Choose auditor "
                      @change="auditorDetail"
                      @remove-tag="deleteAuditor"
                      :disabled="
                        notFoundAuditor == true ||
                        (optionsAuditor.length > 0 && type != 'view')
                          ? false
                          : true
                      "
                    >
                      <el-option
                        v-for="(item, index) in optionsAuditor"
                        :key="index"
                        :label="item.name"
                        :value="item._id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <div v-if="type != 'view'">
                    <el-tag
                      class="detail-auditor"
                      :key="indexS"
                      v-for="(item, indexS) in form.detailAuditor"
                      :disable-transitions="false"
                      closable
                      @close="delAuditor(item)"
                    >
                      <p>
                        {{ item.name }} <br />
                        {{ item.email }} <br />
                        {{ item.phone }}
                      </p>
                    </el-tag>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" :md="12">
              <el-row :gutter="20" type="flex" class="flex-wrap">
                <el-col :span="24" :md="12">
                  <el-form-item label="Date (วันที่)" prop="date">
                    <span v-if="type == 'view'">
                      {{ form.date | formatDateTH }}
                    </span>
                    <el-date-picker
                      v-else
                      type="date"
                      placeholder="Pick a date"
                      v-model="form.date"
                      style="width: 100%"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="24" :md="12">
                  <el-form-item label="Manday">
                    <span v-if="type == 'view'">
                      {{ form.manday }}
                    </span>
                    <el-input
                      v-else
                      v-model="form.manday"
                      @keypress.native="isNumber($event)"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                prop="timeStart"
                label="Time (เวลา)"
                class="time-box"
              >
                <span v-if="type == 'view'">
                  {{ form.timeStart + " - " + form.timeEnd }}
                </span>
                <el-row :gutter="10" v-else>
                  <el-col :span="24" :sm="12">
                    <el-time-select
                      placeholder="Start time"
                      v-model="form.timeStart"
                      @change="changeTime()"
                      :picker-options="{
                        start: '07:00',
                        step: '00:30',
                        end: '18:30',
                        maxTime: form.timeEnd,
                      }"
                    >
                    </el-time-select>
                  </el-col>
                  <el-col :span="24" :sm="12">
                    <el-form-item prop="timeEnd" class="mg-b-less">
                      <el-time-select
                        placeholder="End time"
                        v-model="form.timeEnd"
                        :picker-options="{
                          start: '07:30',
                          step: '00:30',
                          end: '19:00',
                          minTime: form.timeStart,
                        }"
                      >
                      </el-time-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="วิธีการเข้าตรวจ" prop="typeOfVisit">
                <div v-if="type == 'view'">
                  <span v-if="form.typeOfVisit == 1"> Off-site </span>
                  <span v-else-if="form.typeOfVisit == 2"> On-site </span>
                  <span v-else-if="form.typeOfVisit == 3"> Remote </span>
                </div>
                <el-radio-group v-model="form.typeOfVisit" v-else>
                  <el-radio :label="1">Off-site</el-radio>
                  <el-radio :label="2">On-site</el-radio>
                  <el-radio :label="3">Remote</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-card>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
export default {
  props: {
    form: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: "",
    },
    typeMaster: {
      type: Array,
      default: [],
    },
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
      optionsAuditCycle: (state) => state.optionAll.auditCycle,
    }),
  },
  data() {
    return {
      loadingAuto: false,
      refRequisition: [],
      optionsAuditor: [],
      optionsCompany: [],
      optionsStandard: [],
      arrFood: [],
      arrIso: [],
      refNow: [],
      skip: 0,
      limit: 6,
      pageNow: 1,
      totalAll: 0,
      optionTwoType: false,
      notFoundAuditor: false,
      conditionTwoStandard: false,
      optionsSetCatNo: [],
    };
  },
  mounted() {
    this.getRefRequisition();
    if (this.type != "add") {
      this.getAuditor();
    }
  },
  watch: {
    type() {
      if (this.type != "") {
        this.getRefRequisition();
        this.setAuditor();
        this.getAuditor();
      }
    },
  },
  methods: {
    async setAuditor() {
      if (this.type == "view") {
        await this.auditorDetail();
      } else if (this.type == "edit") {
        await this.auditorDetail();
        this.form.setAuditorJson = JSON.parse(
          JSON.stringify(this.form.detailAuditor)
        );

        this.optionsCompany = this.optionsCompanyIso;
        if (this.form.type == 1) {
          this.optionsStandard = this.optionsStandardFood;
        } else if (this.form.type == 2) {
          if (this.form.company == 1) {
            this.optionsStandard = this.optionsStandardIsoOne;
          } else {
            this.optionsStandard = this.optionsStandardIsoTwo;
          }
        }
      }
    },
    getAuditor() {
      this.optionsAuditor = [];
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      if (this.form.type != "" && this.form.standard != "") {
        let obj = {
          company:
            this.form.company == null || this.form.company == ""
              ? ""
              : this.form.company,
          companyType: this.form.type,
          standard: this.form.standard,
        };
        HTTP.post(`matching/auditor`, obj)
          .then((res) => {
            if (res.data.success) {
              if (res.data.obj.length > 0) {
                this.optionsAuditor = res.data.obj.map((row) => {
                  return {
                    name: row.name + " " + row.surname,
                    email: row.email,
                    phone: row.phone,
                    _id: row._id,
                  };
                });
              } else {
                if (this.type != "add") {
                  this.form.auditorId = [];
                  this.notFoundAuditor = true;
                }
              }

              this.setAuditor();
            }
          })
          .catch((e) => {
            if (typeof e.response != "undefined") {
              if (e.response.data.status == 401) {
                this.checkAuth();
              }
            }
          });
      }
    },
    getRefRequisition(skip, limit, type) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        search: "",
        skip: skip != undefined ? skip : this.skip,
        limit: limit != undefined ? limit : this.limit,
      };

      HTTP.post(`certification/ref/requisition`, obj)
        .then((res) => {
          if (res.data.success) {
            if (type != "scroll") {
              this.refRequisition = res.data.obj.map((row) => {
                return {
                  _id: row._id,
                  address: row.address,
                  value: row._id,
                  label: row.companyName,
                  companyType: row.companyType,
                  ...row,
                };
              });
            } else {
              res.data.obj.forEach((row) => {
                this.refRequisition.push({
                  _id: row._id,
                  address: row.address,
                  value: row._id,
                  label: row.companyName,
                  companyType: row.companyType,
                  ...row,
                });
              });
            }
            this.totalAll = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingAuto = false;
        });
    },
    autoChage() {
      let debounce = null;
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        this.getRefRequisition();
      }, 600);
    },
    async scrollToSearch() {
      if (this.refRequisition.length < this.totalAll) {
        this.skip = this.pageNow * this.limit;
        this.loadingAuto = true;
        await this.getRefRequisition(this.skip, this.limit, "scroll");
        this.pageNow++;
      }
    },
    setAddress() {
      this.form.type = "";
      this.form.standard = "";
      this.form.address = "";
      this.form.phone = "";
      this.form.contactPerson = "";
      this.form.catNo = "";
      this.conditionTwoStandard = false;
      this.optionsStandard = [];
      this.optionsCompany = [];
      this.optionsAuditor = [];
      if (this.form.companyName != "") {
        this.form.certificationId = this.form.companyName;
        let findRef = this.refRequisition.find(
          (com) => com.value == this.form.companyName
        );
        if (findRef != undefined) {
          this.form.companyType = findRef.companyType;

          this.refNow = findRef;
          this.arrFood = [];
          this.arrIso = [];

          const groupedByType = findRef.companyType.reduce((acc, obj) => {
            const type = obj.type == 1 ? "food" : "iso";
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(obj);
            return acc;
          }, {});
          if (groupedByType["food"] != undefined) {
            this.arrFood = groupedByType["food"];
            this.form.type = 1;
          }

          if (groupedByType["iso"] != undefined) {
            this.arrIso = groupedByType["iso"];
            this.form.type = 2;
          }

          if (this.arrFood.length > 0 && this.arrIso.length > 0) {
            this.optionTwoType = true;
            this.form.type = "";
          } else {
            this.optionTwoType = false;
          }
          this.selectOptionType();

          this.form.address = findRef.address;
          this.form.phone = findRef.phone;
          this.form.contactPerson = findRef.contactPerson;

          if (findRef.companyType.length == 1) {
            this.form.type = findRef.companyType[0].type;
            if (findRef.companyType[0].type == 1) {
              this.form.company = "";
            }
          }
        }
      }
    },
    auditorDetail() {
      this.form.detailAuditor = [];
      if (this.form.auditorId.length > 0) {
        this.form.auditorId.forEach((auditor) => {
          let find = this.optionsAuditor.find((row) => row._id == auditor);
          this.form.detailAuditor.push(find);
        });
      } else {
        this.form.detailAuditor = [];
      }
    },
    delAuditor(tag) {
      let index = this.form.detailAuditor.findIndex((a) => a._id == tag._id);
      this.deleteAuditor(tag._id);

      this.form.detailAuditor.splice(index, 1);
      this.form.auditorId.splice(index, 1);
    },
    selectOptionType() {
      this.optionsStandard = [];
      this.optionsCompany = [];
      this.form.catNo = "";
      this.form.standard = "";
      this.form.company = "";
      this.form.auditCycle = "";
      this.optionsAuditor = [];
      this.conditionTwoStandard = false;

      if (this.form.type != "") {
        const findByType = this.typeMaster.find(
          (f) => f.typeNum == this.form.type
        );
        if (this.form.type == 1) {
          this.arrFood.forEach((food) => {
            let findStd = findByType.standard.find(
              (row) => row.standardNum == food.standard
            );
            if (findStd != undefined) {
              this.optionsStandard.push(findStd);
            }
          });
        } else if (this.form.type == 2) {
          this.arrIso.forEach((iso) => {
            let findCom = findByType.company.find(
              (row) => row.companyNum == iso.company
            );

            if (findCom != undefined) {
              const idExists = this.optionsCompany.some(
                (obj) => obj.companyNum === findCom.companyNum
              );
              if (!idExists) {
                this.optionsCompany.push(findCom);
              }
            }
          });
        }

        if (this.optionsCompany.length == 1) {
          this.form.company = this.optionsCompany[0].companyNum;
          this.selectOptionCompany();
        }

        if (this.optionsStandard.length == 1) {
          this.form.standard = this.optionsStandard[0].standardNum;
        }

        this.selectStandard();
      }
    },
    selectOptionCompany() {
      this.optionsStandard = [];
      this.optionsAuditor = [];

      if (this.form.company) {

        let findComStd = this.refNow.companyType.filter(
          (row) => row.company == this.form.company
        );
        findComStd.forEach((std) => {
          if (this.form.company == 1) {
            let findStd = this.optionsStandardIsoOne.find(
              (row) => row.standardNum == std.standard
            );
            if (findStd != undefined) {
              const idExists = this.optionsStandard.some(
                (obj) => obj.standardNum === std.standard
              );
              if (!idExists) {
                this.optionsStandard.push(findStd);
              }
            }
          } else if (this.form.company == 2) {
            let findStd = this.optionsStandardIsoTwo.find(
              (row) => row.standardNum == std.standard
            );
            if (findStd != undefined) {
              const idExists = this.optionsStandard.some(
                (obj) => obj.standardNum === std.standard
              );
              if (!idExists) {
                this.optionsStandard.push(findStd);
              }
            }
          }
        });

        // if (findComStd.standard == 99) {
        //   this.form.other = findComStd.other;
        // }

        if (this.optionsStandard.length == 1) {
          this.form.standard = this.optionsStandard[0].standardNum;
        }
        this.selectStandard();
      } else {
        this.form.company = "";
        this.form.standard = "";
        this.form.catNo = "";
        this.form.auditCycle = "";
      }
    },
    selectStandard() {
      this.form.auditorId = [];
      this.form.detailAuditor = [];

      // let findCatAndAudit = this.refNow.companyType.find(
      //   (row) => row.standard == this.form.standard
      // );

      let findCatAndAudits = this.refNow.companyType.filter(
        (row) =>
          row.standard == this.form.standard && row.company == this.form.company
      );

      if (findCatAndAudits.length > 0) {
        if (findCatAndAudits.length > 1) {
          this.conditionTwoStandard = true;
          this.optionsSetCatNo = [];
          findCatAndAudits.forEach((ca) => {
            this.optionsSetCatNo.push({
              value: ca._id,
              label: ca.catNo.join(", "),
            });
          });
        } else {
          this.form.catNo = findCatAndAudits[0].catNo.join(", ");
          this.form.auditCycle = findCatAndAudits[0].auditCycle;
        }
      } else {
        this.form.catNo = "";
        this.form.auditCycle = "";
      }
      // console.log("findCatAndAudits", findCatAndAudits);
      // if (findCatAndAudit != undefined) {
      //   this.form.catNo = findCatAndAudit.catNo.join(", ");
      //   this.form.auditCycle = findCatAndAudit.auditCycle;
      // } else {
      //   this.form.catNo = "";
      //   this.form.auditCycle = "";
      // }

      this.getAuditor();
    },
    changeTime() {
      if (this.form.timeStart >= this.form.timeEnd) {
        this.form.timeEnd = "";
      }
    },
    deleteAuditor(id) {
      if (this.form.oldAuditor != undefined) {
        let findId = this.form.oldAuditor.find((oldId) => oldId == id);
        if (findId != undefined && findId != null) {
          this.form.deleteAuditor.push(findId);
        }
      }
    },
    seleteDefaultAuditCycle() {
      let findAudits = this.refNow.companyType.find(
        (row) => row._id == this.form.catNo
      );
      this.form.auditCycle = findAudits.auditCycle;
    },
  },
};
</script>
