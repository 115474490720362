<template>
  <div>
    <el-table
      :data="data"
      class="table-normal animate__animated animate__fadeIn animate__slow w-100"
      :class="data.length === 0 ? 'empty' : ''"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <img alt="logo" src="@/assets/logo.png" />
        <p>ไม่พบข้อมูล</p>
      </template>
      <el-table-column
        width="80"
        align="center"
        type="index"
        :index="indexMethod"
      >
        <template slot="header">
          <div class="two-line">
            <p>No.</p>
            <p>(ลำดับ)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="250" align="center" prop="companyName">
        <template slot="header">
          <div class="two-line">
            <p>Company name</p>
            <p>(ชื่อบริษัท)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="200"
        prop="address"
        :show-overflow-tooltip="true"
      >
        <template slot="header">
          <div class="two-line">
            <p>Address Of Client</p>
            <p>(ที่อยู่)</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column min-width="200" align="center" label="ผู้ตรวจ">
        <template slot-scope="scope">
          <div v-if="scope.row.userAuditor.length > 0">
            <span
              v-for="(auditor, index) in scope.row.userAuditor"
              :key="index"
              :class="
                scope.row.companyType.type == 1 ? 'color-main-1' : 'color-error'
              "
              >{{ auditor.name + " " + auditor.surname }}
              {{ index != scope.row.userAuditor.length - 1 ? ", " : "" }}</span
            >
          </div>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column min-width="140" align="center" prop="phone">
        <template slot="header">
          <div class="two-line">
            <p>Phone number</p>
            <p>(เบอร์โทร)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <div v-if="scope.row.userAuditor.length > 0">
            <span
              v-for="(auditor, index) in scope.row.userAuditor"
              :key="index"
              :class="
                scope.row.companyType.type == 1 ? 'color-main-1' : 'color-error'
              "
              >{{ auditor.phone }}
              {{ index != scope.row.userAuditor.length - 1 ? ", " : "" }}</span
            >
          </div>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column min-width="120" align="center" prop="type">
        <template slot="header">
          <div class="two-line">
            <p>Type</p>
            <p>(ประเภท)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.companyType.type == 1" class="color-main-1"
            >Food safety</span
          >
          <span v-else-if="scope.row.companyType.type == 2" class="color-error"
            >ISO</span
          >
        </template>
      </el-table-column>
      <el-table-column min-width="100" align="center" prop="company">
        <template slot="header">
          <div class="two-line">
            <p>Company</p>
            <p>(บริษัท)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.companyType.type == 2" class="color-error">
            {{
              mapDataOption(
                scope.row.companyType.company,
                optionsCompanyIso,
                "company"
              )
            }}
          </span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="standard">
        <template slot="header">
          <div class="two-line">
            <p>Standard</p>
            <p>(มารตฐาน)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.companyType.type == 1" class="color-main-1">
            {{
              mapDataOption(
                scope.row.companyType.standard,
                optionsStandardFood,
                "standard"
              )
            }}</span
          >
          <span v-else-if="scope.row.companyType.type == 2" class="color-error">
            {{
              mapDataOption(
                scope.row.companyType.standard,
                scope.row.companyType.company == 1
                  ? optionsStandardIsoOne
                  : optionsStandardIsoTwo,
                "standard"
              )
            }}

            {{
              scope.row.companyType.standard == 99
                ? `(${scope.row.companyType.other})`
                : ""
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        align="center"
        label="CAT No."
        prop="catNo"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.companyType.type == 1 ? 'color-main-1' : 'color-error'
            "
            >{{ scope.row.companyType.catNo.join(" ") }}</span
          >
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="catNo">
        <template slot="header">
          <div class="two-line">
            <p>Date</p>
            <p>(วันที่)</p>
          </div>
        </template>

        <template slot-scope="scope">
          <span>{{ scope.row.date | formatDateTH }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="120" align="center" prop="catNo">
        <template slot="header">
          <div class="two-line">
            <p>Time</p>
            <p>(เวลา)</p>
          </div>
        </template>

        <template slot-scope="scope">
          <span>{{ scope.row.timeStart + " - " + scope.row.timeEnd }}</span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="140"
        align="center"
        label="วิธีการเข้าตรวจรับ"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.typeOfVisit == 1
              ? "Off-site"
              : scope.row.typeOfVisit == 2
              ? "On-site"
              : "Remote"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column width="130px">
        <template slot="header">
          <div class="two-line">
            <p>Action</p>
            <p>(จัดการ)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="box-icon">
            <i
              class="fas fa-eye"
              @click="$emit('openDialogDetail', scope.row._id)"
            ></i>
            <i
              class="fas fa-edit"
              @click="$emit('openDialogEdit', scope.row._id)"
            ></i>
            <i
              class="fas fa-trash-alt"
              @click="$emit('openDialogDelete', scope.row._id)"
            ></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
    }),
  },
  props: {
    data: {
      default: Array,
      return: [],
    },
    loading: {
      type: Boolean,
      return: false,
    },
    page: {
      type: Number,
      return: 0,
    },
    typePage: {
      type: String,
      return: "",
    },
    limit: {
      type: Number,
      return: 0,
    },
  },
  methods: {
    indexMethod(index) {
      return this.page * this.limit - this.limit + index + 1;
    },
  },
};
</script>
