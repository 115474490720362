<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="matching-page"
  >
    <div>
      <h2 class="mg-y-2 text-capitalize">Matching</h2>
      <el-row
        :gutter="15"
        class="mg-t-5 custom-filter"
        :class="user.role == 80 ? 'board' : ''"
      >
        <el-form ref="formFilter" :model="filter" label-position="top">
          <FilterMatching
            :filter="filter"
            :typeMaster="typeMaster"
            :optionsCompany="optionsCompany"
            :optionsStandard="optionsStandard"
            :optionsStatus="optionsStatus"
            @selectOptionType="selectOptionType"
            @selectOptionCompany="selectOptionCompany"
            @searchMasterData="searchFilter"
          />
        </el-form>
      </el-row>
      <div class="is-flex ai-center js-end mg-b-5">
        <el-button
          round
          @click="openHistory()"
          class="mg-t-2 font-14 button-history"
          ><i class="fas fa-history"></i> History log</el-button
        >

        <el-button
          type="warning"
          class="button-radius mg-t-2 font-14"
          @click="(dialogMatching = true), (isModal = 'add')"
          >+ Add Matching</el-button
        >
      </div>
      <el-row :gutter="15" class="mg-b-5">
        <el-col :span="24">
          <TableMatching
            :data="fetchData"
            :loading="loadingTable"
            :page="page"
            :limit="limit"
            @openDialogDelete="openDialogDelete"
            @openDialogEdit="openDialogEdit"
            @openDialogDetail="openDialogDetail"
          />
          <p class="text-right font-12 color-69">Total : {{ total }}</p>

          <div class="text-center mg-y-4">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="changePage"
              :current-page.sync="page"
              :page-size="limit"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-dialog
      :visible.sync="dialogMatching"
      v-if="dialogMatching"
      :before-close="closeForm"
      width="80%"
      :top="`8vh`"
      class="matching-custom"
      :class="isModal"
    >
      <template slot="title">
        <p class="font-28 mg-b-less">
          <span v-if="isModal == 'add'" class="color-main-1">Add Matching</span>
          <span v-else-if="isModal == 'view'" class="color-main-1"
            >Matching</span
          >
          <span v-else-if="isModal == 'edit'" class="color-main-1"
            >Edit Matching</span
          >
        </p>
      </template>
      <el-form
        :model="formMatching"
        :rules="rules"
        ref="ruleForm"
        @submit.native.prevent="submitMatching"
        :label-position="`top`"
      >
        <MatchingCustom
          :form="formMatching"
          :type="isModal"
          :typeMaster="typeMaster"
        />
        <div class="js-end is-flex mg-t-4" v-if="isModal != 'view'">
          <el-button
            type="warning"
            round
            :loading="loadingBtn"
            native-type="submit"
          >
            <span class="font-14"> Save </span>
          </el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-drawer
      class="drawer-history"
      :visible.sync="drawerHistory"
      :before-close="closeHistory"
    >
      <div class="box-header">
        <div>
          <span>History log</span>
        </div>
      </div>
      <div id="custom-collapse" v-if="fetchHistory.length > 0">
        <virtual-list
          v-if="fetchHistory.length > 0"
          class="detail-log list-infinite scroll-touch"
          :data-key="'_id'"
          :data-sources="fetchHistory"
          :data-component="itemComponent"
          v-on:tobottom="scrollToBottom"
          :item-class="'list-item-infinite'"
        >
          <div slot="footer" class="text-center" v-if="loadingHistory">
            <i class="el-icon-loading"></i>
          </div>
        </virtual-list>
      </div>
      <p
        class="font-20 color-75 text-center mg-y-5 pd-t-1"
        v-if="fetchHistory.length == 0"
      >
        ไม่มีข้อมูล
      </p>
    </el-drawer>

    <el-dialog
      :visible.sync="dialogDelete"
      width="45%"
      top="22vh"
      :before-close="closeDelete"
    >
      <template slot="title">
        <p class="font-28 text-center mg-b-less color-main-1">ยืนยันการลบ</p>
      </template>
      <p class="text-center mg-t-less font-16 color-main-1">
        กรุณาตรวจสอบก่อนลบข้อมูล
      </p>

      <span slot="footer" class="dialog-footer is-flex js-between">
        <el-button @click="closeDelete" class="button-radius"
          >ยกเลิก
        </el-button>
        <el-button
          type="warning"
          @click="submitDeleteMatching()"
          class="button-radius"
          >ยืนยัน
        </el-button>
      </span>
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
import FilterMatching from "@/components/filter/FilterMatching";
import TableMatching from "@/components/table/TableMatching";
import VirtualList from "vue-virtual-scroll-list";
import History from "@/components/history/History";
import MatchingCustom from "@/components/matching/MatchingCustom";

export default {
  components: {
    FilterMatching,
    TableMatching,
    "virtual-list": VirtualList,
    MatchingCustom,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      user: (state) => state.user,
      optionsStatus: (state) => state.optionAll.status,
      typeMaster: (state) => state.typeMaster,
    }),
  },
  mounted() {
    this.getMatching();
  },
  data() {
    var validateCom = (rule, value, callback) => {
      if (this.formMatching.type == 2) {
        if (value == "") {
          callback(new Error("กรุณาเลือก Company"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      loading: true,
      loadingTable: false,
      loadingBtn: false,
      loadingHistory: false,
      dialogMatching: false,
      drawerHistory: false,
      filter: {
        search: "",
        companyType: "",
        company: "",
        standard: "",
      },
      fetchData: [],
      optionsType: [],
      optionsCompany: [],
      optionsStandard: [],
      page: 1,
      skip: 0,
      limit: 10,
      total: 20,
      isModal: "",
      formMatching: {
        certificationId: "",
        address: "",
        contactPerson: "",
        phone: "",
        type: "",
        company: "",
        standard: "",
        catNo: "",
        auditCycle: "",
        auditorId: [],
        detailAuditor: [],
        deleteAuditor: [],
        date: "",
        manday: "",
        timeStart: "",
        timeEnd: "",
        typeOfVisit: "",
        companyType: [],
      },
      rules: {
        companyName: [
          {
            required: true,
            message: "กรุณาเลือกชื่อบริษัท",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "กรุณาเลือก Type",
            trigger: "change",
          },
        ],
        company: [
          { validator: validateCom, trigger: "change", required: true },
        ],
        standard: [
          {
            required: true,
            message: "กรุณาเลือก Standard",
            trigger: "change",
          },
        ],
        auditorId: [
          {
            type: "array",
            required: true,
            message: "กรุณาเลือกผู้ตรวจ",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: "กรุณาเลือกวันที่",
            trigger: "change",
          },
        ],
        timeStart: [
          {
            required: true,
            message: "กรุณาเลือกเวลา",
            trigger: "change",
          },
        ],
        catNo: [
          {
            required: true,
            message: "กรุณาเลือก CAT No.",
            trigger: "change",
          },
        ],
        timeEnd: [
          {
            required: true,
            message: "กรุณาเลือกเวลา",
            trigger: "change",
          },
        ],
        typeOfVisit: [
          {
            required: true,
            message: "กรุณาเลือกวิธีการเข้าตรวจ",
            trigger: "change",
          },
        ],
      },
      skipHistory: 0,
      pageNowHistory: 1,
      totalAll: 0,
      itemComponent: History,
      limitHistory: 10,
      fetchHistory: [],
      idActive: "",
      dialogDelete: false,
    };
  },
  methods: {
    async getMatching() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        ...this.filter,
        skip: this.skip,
        limit: this.limit,
      };
      HTTP.post(`matching/all`, obj)
        .then(async (res) => {
          if (res.data.success) {
            this.fetchData = res.data.obj;
            this.total = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.loadingTable = true;
      this.pageNow = page;
      this.skip = page * this.limit - this.limit;
      this.getMatching();
    },
    searchFilter() {
      this.loadingTable = true;
      this.getMatching();
    },
    selectOptionType(valType) {
      this.optionsStandard = [];
      this.optionsCompany = [];
      const findByType = this.typeMaster.find((f) => f.typeNum == valType);

      if (valType == 1) {
        this.optionsStandard = findByType.standard;
      } else if (valType == 2) {
        this.optionsCompany = findByType.company;
      } else {
        this.filter.company = "";
        this.filter.standard = "";
      }
      this.searchFilter();
    },
    selectOptionCompany(valCompany) {
      this.optionsStandard = [];
      this.filter.standard = "";
      if (valCompany) {
        const findByCompany = this.optionsCompany.find(
          (f) => f.companyNum == valCompany
        );
        this.optionsStandard = findByCompany.standard;
      } else {
        this.filter.company = "";
        this.filter.standard = "";
      }
      this.searchFilter();
    },

    closeForm() {
      this.isModal = "";
      this.dialogMatching = false;
      this.$refs["ruleForm"].resetFields();
      this.formMatching = {
        certificationId: "",
        address: "",
        contactPerson: "",
        phone: "",
        type: "",
        company: "",
        standard: "",
        catNo: "",
        auditCycle: "",
        auditorId: [],
        detailAuditor: [],
        deleteAuditor: [],
        date: "",
        manday: "",
        timeStart: "",
        timeEnd: "",
        typeOfVisit: "",
        other: "",
      };
      this.loadingTable = false;
      this.loading = false;
      this.loadingBtn = false;
    },
    submitMatching() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // this.loadingBtn = true;

          let companyTypeId = "";

          if (this.isModal == "add") {
            // กรณีที่มี standard เดียวกันหลายอัน และเลือกจาก cat no
            let findByCat = this.formMatching.companyType.find(
              (row) => row._id == this.formMatching.catNo
            );

            if (findByCat != undefined) {
              companyTypeId = findByCat;
            } else {
              companyTypeId = this.formMatching.companyType.filter(
                (row) =>
                  row.type == this.formMatching.type &&
                  row.standard == this.formMatching.standard
              );
              if (companyTypeId.length > 1) {
                let setCat = this.formMatching.catNo.split(", ");

                let objectWithCat = companyTypeId.find((obj) =>
                  obj.catNo.every((cat) => setCat.includes(cat))
                );
                companyTypeId = objectWithCat;
              } else {
                companyTypeId = companyTypeId[0];
              }
            }
          }

          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          let obj = {
            certificationId: this.formMatching.certificationId,
            companyTypeId: companyTypeId._id,
            auditorId: this.formMatching.auditorId,
            date: this.formMatching.date,
            manday: this.formMatching.manday,
            timeStart: this.formMatching.timeStart,
            timeEnd: this.formMatching.timeEnd,
            typeOfVisit: this.formMatching.typeOfVisit,
            status: 1,
          };
          if (this.isModal == "add") {
            HTTP.post(`matching/create`, obj)
              .then((res) => {
                if (res.data.success) {
                  this.closeForm();
                  this.getMatching();
                  this.$notify({
                    title: "สร้าง Matching สำเร็จ!",
                    type: "success",
                    customClass: "success",
                  });
                } else {
                  this.$notify({
                    title: "ผู้ตรวจ ไม่ว่างในช่วงวันและเวลาดังกล่าว!",
                    type: "error",
                    customClass: "error",
                  });
                  this.loadingBtn = false;
                }
              })
              .catch((e) => {
                if (typeof e.response != "undefined") {
                  if (e.response.data.status == 401) {
                    this.checkAuth();
                  } else {
                    this.alertCatchError(e);
                  }
                }
              })
              .finally(() => {});
          } else if (this.isModal == "edit") {
            this.loadingBtn = false;
            this.formMatching.setAuditorJson.forEach((json) => {
              // let findDelIdAd = this.formMatching.deleteAuditor.find(
              //   (del) => del == json._id
              // );
              // if(findDelIdAd ){}
              let findIdAd = this.formMatching.auditorId.find(
                (del) => del == json._id
              );
              if (findIdAd == undefined) {
                const idExists = this.formMatching.deleteAuditor.some(
                  (auId) => auId == json._id
                );
                if (!idExists) {
                  this.formMatching.deleteAuditor.push(json._id);
                }
              }
            });
            obj.deleteAuditor = this.formMatching.deleteAuditor;
            delete obj.companyTypeId;
            HTTP.put(`matching/edit/` + this.idActive, obj)
              .then((res) => {
                if (res.data.success) {
                  this.closeForm();
                  this.getMatching();
                  this.$notify({
                    title: "แก้ไข Matching สำเร็จ!",
                    type: "success",
                    customClass: "success",
                  });
                } else {
                  this.$notify({
                    title: "ผู้ตรวจ ไม่ว่างในช่วงวันและเวลาดังกล่าว!",
                    type: "error",
                    customClass: "error",
                  });
                  this.loadingBtn = false;
                }
              })
              .catch((e) => {
                if (typeof e.response != "undefined") {
                  if (e.response.data.status == 401) {
                    this.checkAuth();
                  }
                }
              })
              .finally(() => {});
          }
        }
      });
    },

    getMatchingById() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"]

      HTTP.get(`matching/` + this.idActive)
        .then((res) => {
          if (res.data.success) {
            this.formMatching = {
              ...res.data.obj,
              detailAuditor: [],
              deleteAuditor: [],
              setAuditorJson: [],
              oldAuditor: res.data.obj.auditorId,
              type: res.data.obj.companyType.type,
              company: res.data.obj.companyType.company,
              standard: Number(res.data.obj.companyType.standard),
              catNo: res.data.obj.companyType.catNo.join(", "),
              auditCycle: res.data.obj.companyType.auditCycle,
            };
            this.dialogMatching = true;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    getHistoryLog(skip, limit, type) {
      let obj = {
        type: 2,
        skip: skip != undefined ? skip : this.skipHistory,
        limit: limit != undefined ? limit : this.limitHistory,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/history/manage`, obj)
        .then((res) => {
          if (res.data.success) {
            if (type != "scroll") {
              this.fetchHistory = res.data.obj;
            } else {
              res.data.obj.forEach((row) => {
                this.fetchHistory.push({ ...row });
              });
            }
            this.totalAll = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    openHistory() {
      this.drawerHistory = true;
      this.getHistoryLog();
    },

    closeHistory() {
      this.drawerHistory = false;
      this.skipHistory = 0;
      this.pageNowHistory = 1;
    },
    async scrollToBottom() {
      if (this.fetchHistory.length < this.totalAll) {
        this.skipHistory = this.pageNowHistory * this.limitHistory;
        this.loadingHistory = true;
        await this.getHistoryLog(this.skipHistory, this.limitHistory, "scroll");
        this.pageNowHistory++;
      } else {
        this.loadingHistory = false;
      }
    },
    openDialogDetail(id) {
      this.idActive = id;
      this.isModal = "view";
      this.getMatchingById();
    },
    openDialogEdit(id) {
      this.idActive = id;
      this.isModal = "edit";
      this.getMatchingById();
    },
    openDialogDelete(id) {
      this.idActive = id;
      this.dialogDelete = true;
    },

    closeDelete() {
      this.idActive = "";
      this.dialogDelete = false;
    },
    submitDeleteMatching() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.delete(`matching/delete/` + this.idActive)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              title: "ลบ Matching สำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.getMatching();
            this.closeDelete();
          }
        })
        .catch((e) => {
          console.log("fetchTest", e);
        });
    },
  },
};
</script>
